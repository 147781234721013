/* Global Styles */

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-size {
  width: 100%;
  height: 100%;
}

.full-view {
  width: 100vw;
  height: 100vh;
}

iframe {
  border-style: none;
  aspect-ratio: 9/10;
  width: 100%;
}

/* Pages */

.page {
  position: relative;
  min-height: 100vh;
    background-color: #84BEF2;
  background-position: center;
  background-size: 100% auto;
}

.page-title {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.page-title.MuiTypography-h4 {
  font-weight: bold;
}

/* Cards */

.custom-card {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 1rem;
  box-shadow: 0px 7px 7px #02304b81;
  backdrop-filter: saturate(180%) blur(20px);
}

.card-subtitle {
  font-size: 0.95rem;
}

.clickable-card {
  transition: box-shadow 200ms ease-out, transform 100ms ease-in;
}

.clickable-card:hover {
  transform: scale(1.03);
  box-shadow: 20px 20px 20px #02304b81;
}

.clickable-card:active {
  transform: scale(1);
  box-shadow: 0px 7px 7px #02304b81;
}

/* Loading Component */
.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  animation: spin 1s infinite linear;
}

/* Loading Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
