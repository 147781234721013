/* Navbar.css */

.main-navbar.MuiAppBar-root {
  position: static;
  background-color: white;
  color: black;
  font-weight: bold;
  margin: 0;
}

/* Desktop */
@media screen and (min-width: 790px) {
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    top: 100%;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    overflow-x: hidden;
    right: 0;
  }

  .dropdown-content a {
    color: rgb(12, 161, 240);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  #hamburger-menu {
    display: none;
  }

  .MuiAppBar-title {
    flex-grow: 1;
  }

  .nav-link {
    margin-left: 1.5rem;
    text-decoration: none;
    vertical-align: middle;
    color: #54b3d6;  /* Setting a default text color */
    padding: 5px 0;
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  .nav-link:before {
    content: "";
    background: #54b3d6;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }

  .nav-link:hover {
    color: white; /* You can adjust this */
    background-color: #54b3d6;
  }

  .nav-link:hover::before {
    width: 100%;
  }

  .logo {
    max-height: 3rem;
    max-width: 8rem;
    object-fit: contain;
    margin-right: 10px;
  }
}

/* Mobile */
@media screen and (max-width: 789px) {
  .sublink {
    display: none;
  }

  #hamburger-menu {
    display: block;
  }

  .nav-link {
    display: none;
  }

  .logo {
    width: 70px;
    height: 50px;
  }
}
