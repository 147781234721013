/* Desktop */
@media screen and (min-width: 790px) {
  .grant-card.MuiCard-root {
    width: 50rem;
    height: 20rem;

    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 5rem;
  }

  .grant-card-media {
    min-width: 24rem;
    max-width: 24rem;
    height: 100%;
    object-fit: cover;
  }

  .grant-card .MuiCardContent-root {
    padding: 0;
  }

  .grant-card .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
}

/* Mobile */
@media screen and (max-width: 789px) {
  .grant-card.MuiCard-root {
    width: 20rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 5rem;

    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
  }

  .grant-card-media {
    min-height: 13rem;
    max-height: 13rem;
  }

  .learn-more-button {
    width: 80%;
    margin-bottom: 1.5rem;
    align-self: center;
  }

  .grant-card .MuiCardMedia-root {
    width: 100%;
    height: 50%;
    object-fit: cover;
  }

  .grant-card .MuiCardContent-root {
    padding: 0;
  }

  .grant-card .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
}
