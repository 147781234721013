.carousel {
    margin: 2rem 0;
}
.carousel-image {
  width: 100%;
  height: 80vh;
  object-fit: contain;
}

@media (max-width: 768px) {
  .carousel-image {
    height: 40vh;
  }
}

.event-link {
  color: black;
}

.event-link a {
  color: black;
}

.flyer-image {
  width: 100%;
  height: 700px;
  object-fit: contain;
  margin-bottom: 60px;
}

.divider {
  display: block;
  height: 2px;
  background-color: black;
  margin: 4rem 0;
}