.modal-description {
  margin-bottom: 3rem;
}
/* Desktop */
@media screen and (min-width: 790px) {
  .modal .MuiCardMedia-root {
    height: 100%;
    object-fit: cover;
  }

  .modal-individuals-container {
    display: flex;
    justify-content: center;
  }

  .modal-individuals-profile {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .modal-individuals-profile .MuiCardMedia-root {
    width: 5rem;
    height: 5rem;
    clip-path: circle();
    object-fit: cover;
  }

  .modal .MuiBox-root {
    margin: 1.5rem;
  }
}

/* Mobile */
@media screen and (max-width: 789px) {
  .modal {
    padding-top: 1.5rem;
    display: flex;
    justify-content: center;
  }

  #modal-cover {
    display: none;
  }

  .modal-individuals-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modal-individuals-profile {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .modal-individuals-profile .MuiCardMedia-root {
    width: 5rem;
    height: 5rem;
    clip-path: circle();
    object-fit: cover;
  }
}
