/* Home */
.home-banner {
  height: 80vh;
}

.home-banner-video-container {
  height: 100%;
  display: flex;
  justify-content: center;
}

.home-banner-video-container:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #84bfe276;
}

#home-banner-video {
  position: relative;
  object-fit: cover;
  left: 50;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: 106%;
  z-index: -100;
}

.home-banner-text-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 90vw;
  height: 50vh;
  top: 30%;
  left: 0;
  right: 0;
  overflow: hidden;
}

h1.home-banner-text {
  color: white;
  font-size: 8rem;
}
h2.home-banner-text {
  color: white;
  font-size: 5rem;
}

.page-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
  background-color: #84BEF2;
  ;
  background-position: center;
  background-size: cover;
  overflow:scroll;
}



.info-home{
  background-color: white;
  position: relative;
  display: flex;
  margin-bottom: 5rem;
 
 
}


.info-home {
  background-color: white; /* or any color that matches your design */
  padding: 50px 10px;
}


.info-box {
  background: lightblue; /* Light grey background for each info box */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  padding: 20px; /* Padding for internal spacing */
  display: flex;
  flex-direction: column;
  justify-content: flex start; /* Align content to the top */
  align-items: center; /* Center the items horizontally */
  min-height: 350px; /* Minimum height to accommodate content */
  margin: 20px; /* Margin to separate the boxes */
  transition: all 0.3s ease-in-out; /* Smooth transition for interactions */
}

.info-box img {
  width: 100%; /* Make image fill the container width */
  height: 200px; /* Set a fixed height */
  object-fit: cover; /* Cover the area without stretching */
  border-radius: 4px; /* Optional: if you want rounded corners for images */
  margin-bottom: 20px; /* Add some space between the image and the text */
}




/* Responsive adjustments for better mobile view */
@media (max-width: 768px) {
  .info-box {
    margin-bottom: 20px;
  }
}
.info-home {
  background-color: white; 
  padding: 50px 0;
}

.info-image {
  width: 550px;
  height: 450px;
  object-fit: cover;
}


/* Typography Styles */
.info-home .MuiTypography-h6 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.info-home .MuiTypography-body2 {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .info-image {
    margin-bottom: 20px;
  }
}

.instagram-feed{
  text-align: center;
  align-items: center;
  max-width: 600px;
}

.instagram-header{
  margin-bottom: 30px;
}
.instagram-section {
  background-color: white; /* Light background to distinguish the section */
  padding: 80px;
  text-align: center;
}

.instagram-header {
  margin-bottom: 20px;
  color: #333; /* Adjust to match your color scheme */
}

.instagram-subheader {
  margin-bottom: 30px;
}



.about-content{
  background-color: white;
  border-radius: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80vw;
  height: 50vh;
  padding: 1%;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}


.animate{
  width: 50%;
  height: 100%;
  margin: 1% auto;
  position: relative;
  overflow: hidden;
}


.animate.partner1{
  background-size: contain;
  background-repeat: no-repeat;
  height: 5rem;
  background-image: url("../../assets/images/UCM+VISTA_LogoHorizontal.png");
}

.animate.partner2{
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/Slide7.jpg");
}

@media screen and (max-width: 600px){
  .about-content {
    flex-direction: column;
  }

  .animate{
    width: 100%;
    height: 50%;
    object-fit: cover;
  }

  h1.home-banner-text {
    font-size: 4rem;
  }
  h2.home-banner-text {
    font-size: 2.5rem;
  }
}

/* Responsive Image */
.info-image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/* Responsive Typography */
h1.home-banner-text, h2.home-banner-text {
  font-size: 6vw; /* Adjust font size based on the width of the viewport */
}

/* Media Queries */
@media (max-width: 768px) {
  .info-home .MuiTypography-h6 {
    font-size: 1.25rem; /* Smaller font size on small screens */
  }

  .info-home .MuiTypography-body2 {
    font-size: 0.875rem; /* Smaller body text */
  }

  h1.home-banner-text {
    font-size: 8vw; /* Adjust for smaller screens */
  }

  h2.home-banner-text {
    font-size: 5vw;
  }

  .info-image {
    width: 100%; /* Full width on small screens */
    height: auto; /* Adjust height automatically */
  }

  /* Adjust the grid layout for small screens */
  .Grid-container {
    flex-direction: column;
  }
}
/* Enlarge Text */
.info-home .MuiTypography-body2 {
  font-size: 1.25rem; /* Increased from 1rem */
  color: rgba(0, 0, 0, 0.6);
}

/* Enlarge Images */
.info-image {
  width: 600px; /* Increased from 550px */
  height: 400px; /* Increased from 450px */
  object-fit: cover;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .info-home .MuiTypography-body2 {
    font-size: 1rem; /* Slightly smaller font size for mobile */
  }

  .info-image {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Adjust height automatically */
  }
}



/* animated line between  */
.animated-line {
  height: 2px; /* Thickness of the line */
  background-color: #000; /* Color of the line */
  margin: 20px 0; /* Space above and below the line */
  width: 0; /* Initial width */
  animation: drawLine 2s forwards; /* Animation name, duration, and behavior */
}

@keyframes drawLine {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.instagram-section{
  padding: 60px;
  margin: 60px;
  margin-top: 100px;
  margin-bottom: 100px;

}

.instagram-feed-ac{
  padding: 40px;
}





