
.partners-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
    padding: 0px 80px;
    margin-bottom: 30px;
    margin: 90px;
    
  }
  
  .partner-card {
    width: calc(25% - 20px); /* Adjust width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column; 
    align-items: stretch;
    border: 2px solid black; /* This adds a black outline */

  }
  
  .partner-card:hover {
    transform: translateY(-10px);
  }
  
  .partner-card .MuiCardMedia-root {
    object-fit: contain; /* Keep logos aspect ratio */
    padding-top: 16px;
  }
  
  .partner-card .MuiCardContent-root {
    padding: 16px;
    flex:1;
  }
  
  .partner-card .MuiCardActions-root {
    padding: 10px;
    justify-content: center;
    margin-top: auto;
  }

  @media screen and (max-width: 1200px) {
    .partner-card {
      width: calc(33.333% - 20px); /* Adjust width for 3 in a row */
    }
  }
  
  @media screen and (max-width: 900px) {
    .partner-card {
      width: calc(50% - 20px); /* Adjust width for 2 in a row */
    }
  }
  
  @media screen and (max-width: 600px) {
    .partner-card {
      width: calc(100% - 20px); /* Full width on smaller screens */
    }
  }
  
  
  